* {
  margin: 0;
  padding: 0;
}
.bg__general{
  background: url("https://s3-eu-south-2.ionoscloud.com/cursos/5x5/img/intro-WEB.jpg");
  background-size: cover;
  width: 100%;

  height: 100vh;
}

.bg__transparent{
  background: rgba(255, 255, 255, 0.5);
  margin-left: -10px;
  margin-right: -10px;
}
.blue{
  color:#3B486C;
  text-decoration: none;
}
.main {
  width: 100%;
  /* height: 100vh;  */

}
.logo-top{
  position:absolute;
  top:15px;
  right: 30px;
  z-index: 2;
}
video {
  /* position: absolute; */
  position: relative;
  width: 100%;
  max-width: 100%;
  /* height: auto; */
  height: 100% !important;
  object-fit: contain;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
}
.box__content {
  position: absolute;
  width: 100%;
  top:60%;
}
.box-buttons{
  max-width: 500px;
  margin:auto;
}

.btn__cell {
  width: 222px;
  height: 50px;
  background: url("https://s3-eu-south-2.ionoscloud.com/cursos/5x5/img/btn-cell.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  margin:auto;

}
.btn__cell:hover {
  width: 222px;
  height: 50px;
  background: url("https://s3-eu-south-2.ionoscloud.com/cursos/5x5/img/btn-cell-hover.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}
input[type="submit" ]{
  border:none;
  text-align: center;
}
.box-img{
  max-width: 300px;
 
}

/*forms*/

.form-floating > label {
  left:10px
}
.form-floating{
  margin-top: .5rem;
}
button{
  border: none;
  margin: auto;
  font-size: 1.1rem;
  font-weight: 600;
}
.btn-eye{
  position:absolute;
  top:15px;
  right: 20px;
}

/*footer*/
a.nav-links{
  color:#3B486C;
  text-decoration: none;
}